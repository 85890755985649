// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { boardApi } from '../../../api/kanban/boardApi'
import { taskApi } from '../../../api/kanban/taskApi'
import { workspaceApi } from '../../../api/kanban/workspaceApi'

// ** Fetch Boards
export const fetchBoards = createAsyncThunk('appKanban/fetchBoards', async () => {
    try {
        const response = await boardApi.getAll()

        return response
    } catch (err) {

        return err.response.data
    }
})

export const fetchBoardContent = createAsyncThunk('appKanban/fetchBoards', async (board_id) => {
    try {
        const response = await boardApi.getOne(board_id)

        return response
    } catch (err) {

        return err.response.data
    }

})

export const fetchWorkspaces = createAsyncThunk('appKanban/fetchWorkspaces', async () => {
    const response = await workspaceApi.workspaceByUser()

    return response
})

// Daqui pra baixo

export const fetchTasks = createAsyncThunk('appKanban/fetchTasks', async (board_id) => {
    const response = await taskApi.getAllTasksOfBoard({ data: board_id })

    return response
})

export const updateTask = createAsyncThunk('appKanban/updateTask', async (data, { dispatch }) => {
    const response = await axios.post('/apps/kanban/update-task', { data })
    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response.data
})

export const addBoard = createAsyncThunk('appKanban/addBoard', async (data, { dispatch }) => {
    const response = await axios.post('/apps/kanban/add-board', { data })
    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response.data
})

export const addTask = createAsyncThunk('appKanban/addTask', async (data, { dispatch }) => {
    const response = await axios.post('/apps/kanban/add-task', { data })
    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response.data
})

export const clearTasks = createAsyncThunk('appKanban/clearTasks', async (id, { dispatch }) => {
    const response = await axios.delete('/apps/kanban/clear-tasks', { data: id })

    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response
})

export const updateTaskBoard = createAsyncThunk('appKanban/updateTaskBoard', async (data, { dispatch }) => {
    const response = await axios.post('/apps/kanban/update-task-board', { data })
    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response.data
})

export const reorderTasks = createAsyncThunk('appKanban/reorder-tasks', async (data, { dispatch }) => {
    const response = await axios.post('/apps/kanban/reorder-tasks', { data })
    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response.data
})

export const deleteBoard = createAsyncThunk('appKanban/deleteBoard', async (id, { dispatch }) => {
    const response = await axios.delete('/apps/kanban/delete-board', { data: id })

    await dispatch(fetchBoards())
    await dispatch(fetchTasks())

    return response
})

export const appKanbanSlice = createSlice({
    name: 'appKanban',
    initialState: {
        tasks: [],
        columns: [],
        boards: [],
        workspaces: [],
        selectedTask: null
    },
    reducers: {
        handleSelectTask: (state, action) => {
            state.selectedTask = action.payload
        },
        workspaces: (state, action) => {
            state.workspaces = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchBoards.fulfilled, (state, action) => {
                state.boards = action.payload
            })
            .addCase(fetchTasks.fulfilled, (state, action) => {
                state.tasks = action.payload
            })
            .addCase(fetchWorkspaces.fulfilled, (state, action) => {
                state.workspaces = action.payload
            })
            .addCase(fetchBoardContent.rejected)
    }
})

export const { handleSelectTask } = appKanbanSlice.actions

export default appKanbanSlice.reducer