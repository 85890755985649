// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from "./authentication"
import  appKanbanSlice  from "../pages/kanban/store"

const rootReducer = {
  navbar,
  layout,
  auth,
  appKanbanSlice
}

export default rootReducer
