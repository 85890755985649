import axios from "axios"
import toast from "react-hot-toast"

let currentRoute = ''
let cancelTokenSource = axios.CancelToken.source()

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("token"))

    if (window.location.pathname !== currentRoute) {
      currentRoute = window.location.pathname
      cancelTokenSource.cancel('Cancelado devido a mudança de rota')
      cancelTokenSource = axios.CancelToken.source()
    }

    config.headers.Authorization = `Bearer ${accessToken}`
    config.cancelToken = cancelTokenSource.token

    return config
  },
  (error) => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const statusCode = error.response?.status
    const title = error.response?.data?.title

    if (statusCode === 401) {
      localStorage.clear()
      if (window.location.pathname !== '/login') {
        window.location.href = '/login'
      }
    }

    if (statusCode === 403) {
      toast.error(title, { duration: 5000 })
    }

    if (statusCode === 500) {
      toast.error("Ocorreu um erro interno do servidor. Por favor, tente novamente mais tarde.", { duration: 5000 })
    }

    return Promise.reject(error)
  }
)

export { api }
